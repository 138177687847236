import { Add, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useStore from '../../state/index';

dayjs.extend(utc);
dayjs.extend(timezone);

// Field Components
const TypeField = ({ value, onChange, types }) => (
    <TextField select label="Promotion Type" value={value} onChange={(e) => onChange('type', e.target.value)} size="small">
        {types.map((type) => (
            <MenuItem key={type} value={type}>
                {type}
            </MenuItem>
        ))}
    </TextField>
);

const PastDaysField = ({ value, onChange }) => <TextField label="Past No. of Days" type="number" value={value || ''} onChange={(e) => onChange('pastNoOfDays', e.target.value)} size="small" />;

const TotalDenominationField = ({ value, onChange }) => (
    <TextField label="Total Denomination Value Above" type="number" value={value || ''} onChange={(e) => onChange('totalDenominationValueAbove', e.target.value)} size="small" />
);

const ActivationDateField = ({ value, onChange }) => (
    <TextField
        label="Activation Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={value ? dayjs(value).format('YYYY-MM-DD') : ''}
        onChange={(e) => onChange('activationDt', e.target.value)}
        size="small"
    />
);

const DenominationField = ({ value, onChange, index, onRemove }) => (
    <Box display="flex" alignItems="center" gap={1} mb={2}>
        <TextField label={`Denomination ${index + 1}`} type="number" value={value || ''} onChange={(e) => onChange(index, e.target.value)} size="small" />
        <IconButton onClick={() => onRemove(index)} color="error">
            <Delete />
        </IconButton>
    </Box>
);

const DenominationsSection = ({ denominations, onDenominationChange, onAddDenomination, onRemoveDenomination }) => (
    <Box>
        <Typography variant="subtitle2" mt={2} mb={1}>
            Denominations
        </Typography>
        {denominations && denominations.length > 0 ? (
            denominations.map((denomination, index) => <DenominationField key={index} value={denomination} onChange={onDenominationChange} index={index} onRemove={onRemoveDenomination} />)
        ) : (
            <Typography>No denominations available.</Typography>
        )}
        <Button startIcon={<Add />} onClick={onAddDenomination} sx={{ mt: 1 }}>
            Add Denomination
        </Button>
    </Box>
);

// Field Configuration
const PROMOTION_FIELD_CONFIG = {
    VIPMemberDenominations: {
        fields: ['type', 'denominations'],
        defaults: {
            type: 'VIPMemberDenominations',
            denominations: [],
        },
    },
    TotalDenominationFromActivationDateToExtraDenominations: {
        fields: ['type', 'totalDenominationValueAbove', 'activationDt', 'denominations'],
        defaults: {
            type: 'TotalDenominationFromActivationDateToExtraDenominations',
            totalDenominationValueAbove: 0,
            activationDt: '',
            denominations: [],
        },
    },
    FrequencyAndTotalDenominationToExtraDenominations: {
        fields: ['type', 'pastNoOfDays', 'totalDenominationValueAbove', 'denominations'],
        defaults: {
            type: 'FrequencyAndTotalDenominationToExtraDenominations',
            pastNoOfDays: 0,
            totalDenominationValueAbove: 0,
            denominations: [],
        },
    },
};

// Map field types to components
const FIELD_COMPONENTS = {
    type: TypeField,
    pastNoOfDays: PastDaysField,
    totalDenominationValueAbove: TotalDenominationField,
    activationDt: ActivationDateField,
};

// Main Component
const Promotions = ({ promotionTypes, initialPromotions = [], onPromotionsChange }) => {
    const [promotions, setPromotions] = useState(initialPromotions);
    const { userTz } = useStore();

    const additionalPromotionTypes = ['TotalDenominationFromActivationDateToExtraDenominations', 'FrequencyAndTotalDenominationToExtraDenominations', 'VIPMemberDenominations'];

    // Combine server-provided types with additional types, ensuring no duplicates
    const combinedPromotionTypes = Array.from(new Set([...promotionTypes, ...additionalPromotionTypes]));

    const handlePromotionFieldChange = (index, field, value) => {
        const updatedPromotions = [...promotions];
        const currentType = updatedPromotions[index].type;

        if (field === 'type' && currentType !== value) {
            updatedPromotions[index] = {
                ...PROMOTION_FIELD_CONFIG[value].defaults,
                type: value,
            };
        } else {
            updatedPromotions[index] = {
                ...updatedPromotions[index],
                [field]: field === 'pastNoOfDays' || field === 'totalDenominationValueAbove' ? Number(value) : value,
            };
        }

        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions);
    };

    const handleDenominationChange = (promotionIndex, denomIndex, value) => {
        const updatedPromotions = [...promotions];
        const denominations = [...(updatedPromotions[promotionIndex].denominations || [])];
        denominations[denomIndex] = Number(value);
        updatedPromotions[promotionIndex].denominations = denominations;
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions);
    };

    const handleAddDenomination = (promotionIndex) => {
        const updatedPromotions = [...promotions];
        const denominations = [...(updatedPromotions[promotionIndex].denominations || [])];
        denominations.push(0);
        updatedPromotions[promotionIndex].denominations = denominations;
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions);
    };

    const handleRemoveDenomination = (promotionIndex, denomIndex) => {
        const updatedPromotions = [...promotions];
        const denominations = [...(updatedPromotions[promotionIndex].denominations || [])];
        updatedPromotions[promotionIndex].denominations = denominations.filter((_, i) => i !== denomIndex);
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions);
    };

    const handleRemovePromotion = (index) => {
        const updatedPromotions = promotions.filter((_, i) => i !== index);
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions);
    };

    const handleAddPromotion = () => {
        const defaultType = combinedPromotionTypes[0] || '';
        const newPromotion = {
            ...(PROMOTION_FIELD_CONFIG[defaultType]?.defaults || {
                type: defaultType,
                denominations: [],
            }),
        };
        const updatedPromotions = [...promotions, newPromotion];
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions);
    };

    const handleDateChange = (index, value) => {
        const centralTimeDate = dayjs.tz(value, userTz).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        handlePromotionFieldChange(index, 'activationDt', centralTimeDate);
    };

    const renderField = (field, promotion, index) => {
        const Component = FIELD_COMPONENTS[field];
        if (!Component) return null;

        const props = {
            value: promotion[field],
            onChange: (field, value) => handlePromotionFieldChange(index, field, value),
        };

        if (field === 'type') {
            props.types = combinedPromotionTypes;
        }
        if (field === 'activationDt') {
            props.onChange = (field, value) => handleDateChange(index, value);
        }

        return <Component key={field} {...props} />;
    };

    return (
        <Box>
            <Typography variant="h6" component="h2" mt={3} mb={2}>
                Promotions
            </Typography>
            {promotions.length > 0 ? (
                promotions.map((promotion, index) => (
                    <Box key={index} mb={3} border="1px solid #ccc" borderRadius={2} p={2}>
                        <Box display="flex" alignItems="center" gap={2}>
                            {PROMOTION_FIELD_CONFIG[promotion.type]?.fields.filter((field) => field !== 'denominations').map((field) => renderField(field, promotion, index))}
                            <IconButton onClick={() => handleRemovePromotion(index)} color="error">
                                <Delete />
                            </IconButton>
                        </Box>

                        <DenominationsSection
                            denominations={promotion.denominations}
                            onDenominationChange={(denomIndex, value) => handleDenominationChange(index, denomIndex, value)}
                            onAddDenomination={() => handleAddDenomination(index)}
                            onRemoveDenomination={(denomIndex) => handleRemoveDenomination(index, denomIndex)}
                        />
                    </Box>
                ))
            ) : (
                <Typography>No promotions available.</Typography>
            )}
            <Button startIcon={<Add />} onClick={handleAddPromotion} sx={{ mt: 2 }}>
                Add Promotion
            </Button>
        </Box>
    );
};

export default Promotions;
