import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility'; // Import the Visibility icon
import React, { useEffect, useState } from 'react';
import { fetchReportingData, fetchConfigData } from '../../service/index';
import useStore from '../../state/index';
import ImagePreview from '../ImagePreview/ImagePreview';
import Search from '../Search/Search';
import SummaryTable from '../SummaryTable/SummaryTable';
import UserDetails from '../UserDetails/UserDetails';
import './Dashboard.scss';
import { formatDateWithTimeZone } from '../../utils';
import dayjs from 'dayjs';

const Dashboard = () => {
    const [apiResponse, setApiResponse] = useState({ data: [], message: 'No data available, Click Search to load data' });
    const [sortBy, setSortBy] = useState('createTs');
    const [sortDirection, setSortDirection] = useState('desc');
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [action, setAction] = useState('Check_In');
    const [fromDate, setFromDate] = useState(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isShowCheckOut, setIsShowCheckOut] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState([]);
    const [isImagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedShift, setSelectedShift] = useState(null);
    const { apiAccessToken, setInfoMsg, userRole, userTz, siteData, setSiteData } = useStore();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getActiveShift = (shifts, userTz) => {
        const currentTime = dayjs().tz(userTz);

        // Try to find the shift in which currentTime actually falls
        const found = shifts.find((shift) => {
            const shiftStart = dayjs(shift.startTime, 'HH:mm').tz(userTz);
            const shiftEnd = dayjs(shift.endTime, 'HH:mm').tz(userTz);

            // Handle crossing midnight
            if (shiftEnd.isBefore(shiftStart)) {
                // If shiftEnd < shiftStart, the shift wraps past midnight.
                // This means any time >= shiftStart or < shiftEnd is within the shift.
                return currentTime.isAfter(shiftStart) || currentTime.isBefore(shiftEnd);
            } else {
                // Normal case: shiftEnd is the same or after shiftStart
                // Check if currentTime is between them
                return currentTime.isBetween(shiftStart, shiftEnd, null, '[)');
            }
        });

        // If no matching shift is found (e.g., you have partial coverage or some schedule edge case),
        // you could default to the original "closest shift" logic OR return null.
        return found || null;
    };

    const loadDataFromServer = async (action, fromDateOrName, shift) => {
        try {
            if (!siteData || siteData.length === 0 || !shift) {
                const configResponse = await fetchConfigData(apiAccessToken, userTz);
                const shifts = configResponse?.data?.formattedData?.[0]?.shifts;

                if (!shift && shifts && shifts.length > 0) {
                    // Use the function above
                    const activeShift = getActiveShift(shifts, userTz);

                    // If we found an active shift, use that;
                    // otherwise, do fallback if needed (e.g., nearest shift).
                    if (activeShift) {
                        shift = activeShift;
                    } else {
                        // Fallback: nearest shift (only if needed)
                        const currentTime = dayjs().tz(userTz);
                        shift = shifts.reduce((closest, currentShift) => {
                            const st = dayjs(currentShift.startTime, 'HH:mm').tz(userTz);
                            const diff = Math.abs(st.diff(currentTime, 'minute'));
                            if (!closest || diff < closest.diff) {
                                return { shift: currentShift, diff };
                            }
                            return closest;
                        }, null)?.shift;
                    }

                    setSelectedShift(shift);
                    setIsShowCheckOut(configResponse?.data?.formattedData?.[0]?.showCheckOut || false);
                }

                setSiteData(configResponse.data.formattedData);
            }

            // Continue with your reporting call
            const reportingResponse = await fetchReportingData(apiAccessToken, action, fromDateOrName, userTz, '', shift);
            setApiResponse(reportingResponse);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        loadDataFromServer(action, new Date(), selectedShift);
    }, [apiAccessToken]);

    const filterData = () => {
        if (apiResponse) {
            setFilteredData(apiResponse.data.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()) || String(item.phone).includes(searchQuery)));
        }
    };

    useEffect(() => {
        filterData();
    }, [searchQuery, apiResponse]);

    const handleSort = (column) => {
        const isAsc = sortBy === column && sortDirection === 'asc';
        setSortBy(column);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    const sortedData = filteredData.sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (['createTs', 'currentCheckInDt', 'lastCheckInDt'].includes(sortBy)) {
            return sortDirection === 'asc' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        }

        return 0;
    });

    const displayedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleDialogOpen = async (row) => {
        try {
            const response = await fetchReportingData(apiAccessToken, 'UserDetails', row.name, '', row.phone);
            if (response && response.data) {
                setSelectedUserData(response.data.sort((a, b) => new Date(b.createTs) - new Date(a.createTs)));
            } else {
                console.error('No data found in API response');
                setSelectedUserData([]);
            }
            setIsDialogOpen(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
            setSelectedUserData([]);
        }
    };

    return (
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
            <SummaryTable data={filteredData} action={action} />

            <Search
                fromDate={fromDate}
                setFromDate={setFromDate}
                action={action}
                setAction={setAction}
                handleSearchBtnClick={() => loadDataFromServer(action, fromDate, selectedShift)}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                shifts={siteData?.[0]?.shifts || []}
                selectedShift={selectedShift}
                setSelectedShift={setSelectedShift}
                isShowCheckOut={isShowCheckOut}
                userRole={userRole}
            />

            <Table sx={{ minWidth: 650 }}>
                {!isMobile && (
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Photo</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Name</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Phone</strong>
                            </TableCell>
                            {action !== 'Registration' && (
                                <TableCell>
                                    <strong>{action === 'Check_In' ? 'Check-In Denomination' : 'Check-Out Denomination'}</strong>
                                </TableCell>
                            )}
                            {action === 'Check_Out' && (
                                <TableCell>
                                    <strong>Machine Number</strong>
                                </TableCell>
                            )}
                            <TableCell>
                                <strong> {action === 'Check_In' ? 'Check-In Time' : action === 'Check_Out' ? 'Check-Out Time' : 'Registered On'}</strong>
                            </TableCell>
                            {userRole !== 'Employee' && (
                                <TableCell>
                                    <strong>Details</strong>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {displayedData.length > 0 ? (
                        displayedData.map((item, index) => (
                            <TableRow key={index}>
                                {isMobile ? (
                                    <>
                                        <TableCell
                                            sx={{
                                                padding: '8px', // Adjust padding for the cell
                                                textAlign: 'center', // Center the image horizontally
                                                verticalAlign: 'middle', // Center the image vertically if needed
                                            }}
                                        >
                                            {item.imgBase64 ? (
                                                <img
                                                    src={`data:image/jpeg;base64,${item.imgBase64}`}
                                                    alt="thumbnail"
                                                    style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setSelectedImage(item.imgBase64);
                                                        setImagePreviewOpen(true);
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        height: '100px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#e0e0e0',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        fontSize: '0.8rem',
                                                        color: '#757575',
                                                    }}
                                                >
                                                    No Photo
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                padding: '8px', // Adjust padding for the cell
                                                verticalAlign: 'middle', // Center the image vertically if needed
                                            }}
                                        >
                                            <strong>Name:</strong> {item.name || 'N/A'}
                                            <br />
                                            <strong>Phone:</strong> {item.phone || 'N/A'}
                                            <br />
                                            {action !== 'Registration' && (
                                                <>
                                                    <strong>Denomination:</strong> ${action === 'Check_In' ? item.denomination || '0' : item.cashOutAmount || '0'}
                                                    <br />
                                                </>
                                            )}
                                            {action === 'Check_Out' && (
                                                <>
                                                    <strong>Machine:</strong> {item.machineNumber || 'N/A'}
                                                    <br />
                                                </>
                                            )}
                                            <strong> {action === 'Check_In' ? 'Check-In:' : action === 'Check_Out' ? 'Check-Out:' : 'Registration:'}</strong>{' '}
                                            {formatDateWithTimeZone(action === 'Check_In' ? item.currentCheckInDt : action === 'Check_Out' ? item.currentCheckOutDt : item.createTs, userTz)}
                                            <br />
                                            {userRole !== 'Employee' && (
                                                <IconButton onClick={() => handleDialogOpen(item)} style={{ marginTop: '8px' }}>
                                                    <VisibilityIcon fontSize="small" sx={{ color: 'blue' }} />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell>
                                            {item.imgBase64 ? (
                                                <img
                                                    src={`data:image/jpeg;base64,${item.imgBase64}`}
                                                    alt="thumbnail"
                                                    style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setSelectedImage(item.imgBase64);
                                                        setImagePreviewOpen(true);
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        height: '100px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#e0e0e0',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        fontSize: '0.8rem',
                                                        color: '#757575',
                                                    }}
                                                >
                                                    No Photo
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell>{item.name || 'N/A'}</TableCell>
                                        <TableCell>{item.phone || 'N/A'}</TableCell>
                                        {action !== 'Registration' && <TableCell>${action === 'Check_In' ? item.denomination || '0' : item.cashOutAmount || '0'}</TableCell>}
                                        {action === 'Check_Out' && <TableCell>{item.machineNumber || 'N/A'}</TableCell>}
                                        <TableCell>
                                            {formatDateWithTimeZone(action === 'Check_In' ? item.currentCheckInDt : action === 'Check_Out' ? item.currentCheckOutDt : item.createTs, userTz)}
                                        </TableCell>
                                        {userRole !== 'Employee' && (
                                            <TableCell>
                                                <IconButton onClick={() => handleDialogOpen(item)} disabled={userRole === 'Employee'}>
                                                    <VisibilityIcon fontSize="small" sx={{ color: 'blue' }} />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                    </>
                                )}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={isMobile ? 2 : 6} style={{ textAlign: 'center' }}>
                                <Typography>Please adjust criteria & Click on Search</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
            />

            <ImagePreview isOpen={isImagePreviewOpen} image={selectedImage} onClose={() => setImagePreviewOpen(false)} />

            <UserDetails isOpen={isDialogOpen} data={selectedUserData} onClose={() => setIsDialogOpen(false)} timeZone={userTz} />
        </TableContainer>
    );
};

export default Dashboard;
