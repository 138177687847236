import { Add, Delete } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { fetchConfigData, saveConfigData } from '../../service/index';
import useStore from '../../state/index';
import Promotions from './Promotions';
import dayjs from 'dayjs';

const SiteDetails = () => {
    const [selectedSiteIndex, setSelectedSiteIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const { apiAccessToken, setInfoMsg, setErrorMsg, setSuccessMsg, siteData, setSiteData, userTz } = useStore();
    const [promotionTypes, setPromotionTypes] = useState([]);
    const [promotions, setPromotions] = useState([]);

    const handlePromotionsChange = (updatedPromotions) => {
        setPromotions(updatedPromotions);
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true); // Start loading state
                const response = await fetchConfigData(apiAccessToken, userTz);
                if (response?.data) {
                    setSiteData(response.data.formattedData);
                    setPromotions(response.data.allPromotions);
                    setPromotionTypes(response.data.uniquePromotionTypes);
                    // setInfoMsg(response.message || 'Data loaded successfully.');
                } else {
                    setErrorMsg('No site data found.');
                }
            } catch (error) {
                console.error('Error fetching site details:', error);
                setErrorMsg('Error fetching site details. Please try again later.');
            } finally {
                setLoading(false); // End loading state
            }
        };

        loadData();
    }, [apiAccessToken]);

    const handleSiteChange = (event) => {
        setSelectedSiteIndex(Number(event.target.value));
    };

    const updateKey = (field, value) => {
        const updatedData = [...siteData];
        const site = { ...updatedData[selectedSiteIndex] };
        site[field] = value;
        updatedData[selectedSiteIndex] = site;
        setSiteData(updatedData);
    };

    const handleDenominationChange = (index, value) => {
        const updatedData = [...siteData];
        const denominations = [...(updatedData[selectedSiteIndex].denominations || [])];
        denominations[index] = Number(value);
        updatedData[selectedSiteIndex].denominations = denominations;
        setSiteData(updatedData);
    };

    const handleAddDenomination = () => {
        const updatedData = [...siteData];
        const denominations = [...(updatedData[selectedSiteIndex].denominations || [])];
        denominations.push(0);
        updatedData[selectedSiteIndex].denominations = denominations;
        setSiteData(updatedData);
    };

    const handleRemoveDenomination = (index) => {
        const updatedData = [...siteData];
        const denominations = [...(updatedData[selectedSiteIndex].denominations || [])];
        updatedData[selectedSiteIndex].denominations = denominations.filter((_, i) => i !== index);
        setSiteData(updatedData);
    };

    const handleShiftChange = (index, field, value) => {
        const updatedData = [...siteData];
        const shifts = [...(updatedData[selectedSiteIndex].shifts || [])];
        shifts[index] = { ...shifts[index], [field]: value };
        updatedData[selectedSiteIndex].shifts = shifts;
        setSiteData(updatedData);
    };

    const handleAddShift = () => {
        const updatedData = [...siteData];
        const shifts = [...(updatedData[selectedSiteIndex].shifts || [])];
        shifts.push({
            startTime: null,
            endTime: null,
            order: shifts.length + 1,
        });
        updatedData[selectedSiteIndex].shifts = shifts;
        setSiteData(updatedData);
    };

    const handleRemoveShift = (index) => {
        const updatedData = [...siteData];
        const shifts = [...(updatedData[selectedSiteIndex].shifts || [])];
        updatedData[selectedSiteIndex].shifts = shifts.filter((_, i) => i !== index);
        setSiteData(updatedData);
    };

    const normalizeTime = (time) => {
        if (!time || !time.isValid()) return null;
        return time
            .hour(time.hour())
            .minute(time.minute())
            .second(time.second())
            .millisecond(0)
            .date(1) // Normalize to a single reference day
            .month(0)
            .year(2000);
    };

    const validateAndReorderShifts = () => {
        let shifts = siteData[selectedSiteIndex]?.shifts || [];
        if (shifts.length === 0) return true; // No shifts, nothing to validate

        const originalOrder = [...shifts];

        // Sort shifts by startTime, accounting for overnight shifts
        shifts = shifts.sort((a, b) => {
            const startA = normalizeTime(a.startTime);
            const startB = normalizeTime(b.startTime);
            return startA.isBefore(startB) ? -1 : 1;
        });

        siteData[selectedSiteIndex].shifts = shifts;

        let earliestStart = null;
        let latestEnd = null;

        for (let i = 0; i < shifts.length; i++) {
            const currentShift = shifts[i];
            const { startTime, endTime } = currentShift;

            if (!startTime || !endTime || !startTime.isValid() || !endTime.isValid()) {
                const originalShift = originalOrder.find((shift) => shift.order === currentShift.order);
                setErrorMsg(`Shift ${originalShift.order} has invalid start or end time.`);
                return false;
            }

            const normalizedStart = normalizeTime(startTime);
            let normalizedEnd = normalizeTime(endTime);

            // Handle overnight shifts by rolling the end time to the next day if needed
            if (normalizedEnd.isBefore(normalizedStart)) {
                normalizedEnd.add(1, 'day');
            }

            if (!earliestStart || normalizedStart.isBefore(earliestStart)) {
                earliestStart = normalizedStart;
            }
            if (!latestEnd || normalizedEnd.isAfter(latestEnd)) {
                latestEnd = normalizedEnd;
            }

            const shiftDuration = normalizedEnd.diff(normalizedStart, 'hours');
            if (shiftDuration > 24) {
                const originalShift = originalOrder.find((shift) => shift.order === currentShift.order);
                setErrorMsg(`Shift ${originalShift.order} exceeds the 24-hour limit.`);
                return false;
            }

            // Enhanced Overlap Check — allow the next shift to start exactly when the previous one ends
            if (i < shifts.length - 1) {
                let nextStartTime = normalizeTime(shifts[i + 1].startTime);
                if (nextStartTime.isBefore(normalizedStart)) {
                    nextStartTime.add(1, 'day');
                }
                if (normalizedEnd.isAfter(nextStartTime)) {
                    if (!normalizedEnd.isSame(nextStartTime) && normalizedEnd.valueOf() !== nextStartTime.valueOf()) {
                        // Allow exact match or next shift to start right after
                        setErrorMsg(`Shift ending at ${endTime.format('h:mm:ss A')} overlaps with Shift starting at ${shifts[i + 1].startTime.format('h:mm:ss A')}.`);
                        return false;
                    }
                }
            }
        }

        const totalDuration = latestEnd.diff(earliestStart, 'hours');
        if (totalDuration > 24) {
            setErrorMsg(`The total time covered by shifts (${earliestStart.format('h:mm A')} to ${latestEnd.format('h:mm A')}) exceeds the 24-hour window (${totalDuration} hours).`);
            return false;
        }

        return true; // All validations passed
    };

    const handleSave = async () => {
        if (!validateAndReorderShifts()) return;

        setSaving(true);
        try {
            const preparedData = { ...siteData[selectedSiteIndex] };

            // Convert shift times back to 24-hour format for the backend
            preparedData.shifts = preparedData.shifts.map((shift) => ({
                order: shift.order,
                startHour: shift.startTime ? parseInt(shift.startTime.format('HH'), 10) : null,
                endHour: shift.endTime ? parseInt(shift.endTime.format('HH'), 10) : null,
            }));

            // Convert `validUntil` to ISO string
            preparedData.validUntil = preparedData.validUntil ? preparedData.validUntil.toISOString() : null;
            preparedData.promotions = promotions;
            let response = await saveConfigData(apiAccessToken, preparedData);
            if (response.status == 200) {
                setSuccessMsg('Config saved successfully.');
            } else {
                setErrorMsg('Error saving site details.');
            }
        } catch (error) {
            console.error('Error saving site details:', error);
            setErrorMsg('Error saving site details.');
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (siteData.length === 0) {
        return <Typography color="error">No site data available.</Typography>;
    }

    const selectedSite = siteData[selectedSiteIndex];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
                <Typography variant="h5" component="h2" mt={4} mb={3}>
                    Edit Site Details
                </Typography>

                {/* Site Selector */}
                <TextField select label="Select Machine" value={selectedSiteIndex} onChange={handleSiteChange} margin="normal">
                    {siteData.map((site, index) => (
                        <MenuItem key={site.siteId} value={index}>
                            {site.key}
                        </MenuItem>
                    ))}
                </TextField>

                {/* Valid Until Field */}
                <br />
                <br />
                <DatePicker
                    disabled
                    label="Valid Until"
                    value={selectedSite.validUntil}
                    onChange={(newValue) => updateKey('validUntil', newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />

                {/* Re-Checkin Allowed After Hours Dropdown */}
                <Typography variant="h6" component="h2" mt={3} mb={2}>
                    Re-Checkin Allowed After Hours
                </Typography>
                <TextField
                    select
                    label="Re-Checkin Allowed After Hours"
                    value={selectedSite.reCheckinAllowedAfterHrs !== undefined ? selectedSite.reCheckinAllowedAfterHrs : ''}
                    onChange={(event) => updateKey('reCheckinAllowedAfterHrs', Number(event.target.value))}
                    margin="normal"
                >
                    {[...Array(25).keys()].map((hour) => (
                        <MenuItem key={hour} value={hour}>
                            {hour} hrs
                        </MenuItem>
                    ))}
                </TextField>

                {/* Denominations Section */}
                <Typography variant="h6" component="h2" mt={3} mb={2}>
                    Denominations
                </Typography>
                <Box>
                    {selectedSite.denominations && selectedSite.denominations.length > 0 ? (
                        selectedSite.denominations.map((denomination, index) => (
                            <Box key={index} display="flex" alignItems="center" gap={1} mb={3}>
                                <TextField
                                    label={`Denomination ${index + 1}`}
                                    type="number"
                                    value={denomination ? Number(denomination) : ''}
                                    onChange={(e) => handleDenominationChange(index, e.target.value)}
                                    size="small"
                                />
                                <IconButton onClick={() => handleRemoveDenomination(index)} color="error">
                                    <Delete />
                                </IconButton>
                            </Box>
                        ))
                    ) : (
                        <Typography>No denominations available.</Typography>
                    )}
                    <Button startIcon={<Add />} onClick={handleAddDenomination}>
                        Add Denomination
                    </Button>
                </Box>

                {/* Shifts Section */}
                <Typography variant="h6" component="h2" mt={3} mb={2}>
                    Shifts
                </Typography>
                {selectedSite.shifts && selectedSite.shifts.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Shift</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedSite.shifts.map((shift, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        <TimePicker
                                            label="Start Time"
                                            views={['hours']}
                                            value={shift.startTime}
                                            onChange={(newValue) => handleShiftChange(index, 'startTime', newValue)}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TimePicker
                                            label="End Time"
                                            views={['hours']}
                                            value={dayjs(shift.endTime).add(dayjs(shift.endTime).minute() === 59 && dayjs(shift.endTime).second() === 59 ? 1 : 0, 'hour')}
                                            onChange={(newValue) => handleShiftChange(index, 'endTime', newValue)}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleRemoveShift(index)} color="error">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Typography>No shifts available.</Typography>
                )}
                <Button startIcon={<Add />} onClick={handleAddShift} sx={{ mt: 1 }}>
                    Add Shift
                </Button>

                <Promotions promotionTypes={promotionTypes} initialPromotions={promotions} onPromotionsChange={handlePromotionsChange} />

                {/* Save Button */}
                <Box mt={4}>
                    <Button variant="contained" onClick={handleSave} disabled={saving} sx={{ mr: 2 }}>
                        {saving ? 'Saving...' : 'Save'}
                    </Button>
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default SiteDetails;
