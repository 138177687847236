import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
    useMediaQuery,
    useTheme,
    IconButton,
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
} from '@mui/material';
import { fetchReportingData } from '../../service/index';
import useStore from '../../state/index';
import { formatDateWithTimeZone } from '../../utils';
import './EmployeeCheckins.scss';
import ImagePreview from '../ImagePreview/ImagePreview'; // Reuse ImagePreview component
import EmployeeDetails from '../EmployeeDetails/EmployeeDetails';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const EmployeeCheckins = () => {
    const [apiResponse, setApiResponse] = useState({ data: [], message: 'No data available, Click Search to load data' });
    const [filteredData, setFilteredData] = useState([]);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [addInOrOutEntryOpen, setAddInOrOutEntryOpen] = useState(false);
    const [selectedInOrOutRow, setSelectedInOrOutEntryRow] = useState(null);
    const [selectedInOrOutEntryOption, setSelectedInOrOutEntryOption] = useState('');
    const [inOrOutEntryDateTime, setInOrOutEntryDateTime] = useState('');
    const [selectedItemToDelete, setSelectedItemToDelete] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [isUserDetailsOpen, setUserDetailsOpen] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('createTs'); // Default sorting column
    const [sortDirection, setSortDirection] = useState('desc'); // Default sorting direction

    const { apiAccessToken, userTz, setSuccessMsg, setErrorMsg } = useStore();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const loadDataFromServer = async () => {
        setLoading(true);
        try {
            const response = await fetchReportingData(apiAccessToken, 'EmployeeCheckins', null, null);
            setApiResponse(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadDataFromServer();
    }, [apiAccessToken]);

    const filterData = () => {
        if (apiResponse) {
            setFilteredData(apiResponse.data.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()) || String(item.phone).includes(searchQuery)));
        }
    };

    useEffect(() => {
        filterData();
    }, [searchQuery, apiResponse]);

    const handleSort = (column) => {
        const isAsc = sortBy === column && sortDirection === 'asc';
        setSortBy(column);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    const displayedData = [...filteredData]
        .sort((a, b) => {
            const aValue = a[sortBy];
            const bValue = b[sortBy];

            if (sortBy === 'createTs') {
                return sortDirection === 'asc' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
            }

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            return 0;
        })
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setImagePreviewOpen(true);
    };

    const handleViewButtonClick = async (user) => {
        try {
            const response = await fetchReportingData(apiAccessToken, 'UserDetails', user.name, '', user.phone);
            if (response && response.data) {
                setSelectedUserData(response.data.sort((a, b) => new Date(b.createTs) - new Date(a.createTs)));
            } else {
                console.error('No data found in API response');
                setSelectedUserData([]);
            }
            setUserDetailsOpen(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const handleInOrOutButtonClick = async (user) => {
        setSelectedInOrOutEntryRow(user);
        setAddInOrOutEntryOpen(true);
    };

    const handleInOrOutOptionChange = (event) => {
        setSelectedInOrOutEntryOption(event.target.value);
    };

    const handleInOrOutDateTimeChange = (event) => {
        setInOrOutEntryDateTime(event.target.value);
    };

    const handleInOrOutClose = () => {
        setAddInOrOutEntryOpen(false);
        setSelectedInOrOutEntryRow(null);
        setSelectedInOrOutEntryOption('');
        setInOrOutEntryDateTime('');
    };
    const handleInOrOutAdd = async () => {
        const date = new Date(inOrOutEntryDateTime);
        const timezoneOffset = date.getTimezoneOffset();
        const sign = timezoneOffset > 0 ? '-' : '+';
        const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
        const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
        // Format the date to local time with timezone offset
        const localTimeWithTimezone = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(
            2,
            '0'
        )}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(3, '0')}${sign}${offsetHours}:${offsetMinutes}`;

        let data = { inOrOutAction: selectedInOrOutEntryOption, forDateTs: localTimeWithTimezone };
        try {
            const response = await fetchReportingData(apiAccessToken, 'InOrOut', selectedInOrOutRow.name, '', selectedInOrOutRow.phone, '', data);
            if (response.status === 400) {
                console.error('Bad Request: The server could not understand the request.');
                setErrorMsg(`FAILED to ${selectedInOrOutEntryOption} for Employee ${selectedInOrOutRow.name}.`);
            } else if (response && response.data) {
                setSuccessMsg(`${selectedInOrOutEntryOption} Success for Employee ${selectedInOrOutRow.name}.`);
            } else if (response && response.message) {
                console.error('API response message:', response.message);
                setErrorMsg(`FAILED to ${selectedInOrOutEntryOption} for Employee ${selectedInOrOutRow.name}.`);
            } else {
                console.error('No data found in API response');
                setErrorMsg(`FAILED to ${selectedInOrOutEntryOption} for Employee ${selectedInOrOutRow.name}.`);
            }
        } catch (error) {
            console.error('Error saving Check In or Out details:', error);
        }
        handleInOrOutClose();
    };

    const handleDeleteButtonClick = async (user) => {
        if (!user) {
            return;
        }
        try {
            const response = await fetchReportingData(apiAccessToken, 'UnReg', user.name, '', user.phone);
            if (response.status === 400) {
                console.error('Bad Request: The server could not understand the request.');
                setErrorMsg(`User ${user.name} with Phone ${user.phone} FAILED to Delete.`);
            } else if (response && response.data) {
                setSuccessMsg(`User ${user.name} with Phone ${user.phone} Deleted.`);
                // Remove the user record from the main data
                setApiResponse((prev) => ({
                    ...prev,
                    data: prev.data.filter((item) => !(item.name === user.name && item.phone === user.phone)),
                }));
                // Remove the user record from the filtered data
                setFilteredData((prev) => prev.filter((item) => !(item.name === user.name && item.phone === user.phone)));
            } else if (response && response.message) {
                console.error('API response message:', response.message);
                setErrorMsg(`User ${user.name} with Phone ${user.phone} FAILED to Delete.`);
            } else {
                console.error('No data found in API response');
                setErrorMsg(`User ${user.name} with Phone ${user.phone} FAILED to Delete.`);
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMsg(`User ${user.name} with Phone ${user.phone} FAILED to Delete.`);
        }
    };

    const handleDeleteClickOpen = (item) => {
        setSelectedItemToDelete(item);
        setDeleteConfirmOpen(true);
    };

    const handleDeleteClickClose = () => {
        setSelectedItemToDelete(null);
        setDeleteConfirmOpen(false);
    };

    const handleConfirmDelete = () => {
        handleDeleteButtonClick(selectedItemToDelete);
        handleDeleteClickClose();
    };

    const DialogTitleWithClose = styled(DialogTitle)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }));

    const getCurrentDateTime = () => {
        const now = new Date();
        return now.toISOString().slice(0, 16);
    };

    return (
        <TableContainer component={Paper} className="employee-checkins-container">
            <Typography variant="h6" className="title">
                Employee CheckIns
            </Typography>

            {loading ? (
                <div className="loading-indicator">
                    <CircularProgress />
                </div>
            ) : (
                <Table className="data-table">
                    <Dialog open={addInOrOutEntryOpen} onClose={handleInOrOutClose}>
                        <DialogTitleWithClose>
                            Check In / Check Out
                            <IconButton onClick={handleInOrOutClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitleWithClose>
                        <DialogContent>
                            <RadioGroup value={selectedInOrOutEntryOption} onChange={handleInOrOutOptionChange}>
                                <FormControlLabel value="Check_In" control={<Radio />} label="Check In" />
                                <FormControlLabel value="Check_Out" control={<Radio />} label="Check Out" />
                            </RadioGroup>
                            <TextField
                                label="Date & Time"
                                type="datetime-local"
                                value={inOrOutEntryDateTime}
                                onChange={(e) => setInOrOutEntryDateTime(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                inputProps={{
                                    max: getCurrentDateTime(),
                                }}
                                style={{ minWidth: '100%' }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleInOrOutAdd} disabled={!selectedInOrOutEntryOption || !inOrOutEntryDateTime}>
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={deleteConfirmOpen} onClose={handleDeleteClickClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete User <strong>{selectedItemToDelete && selectedItemToDelete.name}</strong> with Phone{' '}
                                <strong>{selectedItemToDelete && selectedItemToDelete.phone}</strong> ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteClickClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {!isMobile && (
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <strong>Photo</strong>
                                </TableCell>
                                <TableCell sortDirection={sortBy === 'name' ? sortDirection : false}>
                                    <TableSortLabel active={sortBy === 'name'} direction={sortDirection} onClick={() => handleSort('name')}>
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <strong>Phone</strong>
                                </TableCell>
                                <TableCell sortDirection={sortBy === 'createTs' ? sortDirection : false}>
                                    <TableSortLabel active={sortBy === 'createTs'} direction={sortDirection} onClick={() => handleSort('createTs')}>
                                        Registered On
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <strong>Actions</strong>
                                </TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {displayedData.length > 0 ? (
                            displayedData.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{
                                            padding: '8px',
                                            textAlign: 'center',
                                            width: isMobile ? '80px' : '100px',
                                        }}
                                    >
                                        {item.imgBase64 ? (
                                            <img
                                                src={`data:image/jpeg;base64,${item.imgBase64}`}
                                                alt="thumbnail"
                                                style={{
                                                    width: isMobile ? '80px' : '100px',
                                                    height: isMobile ? '80px' : '100px',
                                                    borderRadius: '8px',
                                                    objectFit: 'cover',
                                                }}
                                                onClick={() => handleImageClick(item.imgBase64)}
                                            />
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    {isMobile ? (
                                        <TableCell
                                            sx={{
                                                padding: '8px',
                                                fontSize: '0.9rem',
                                                lineHeight: '1.4',
                                            }}
                                        >
                                            <strong>Name:</strong> {item.name || 'N/A'}
                                            <br />
                                            <strong>Phone:</strong> {item.phone || 'N/A'}
                                            <br />
                                            <strong>Registered On:</strong> {formatDateWithTimeZone(item.createTs, userTz)}
                                            <br />
                                            <IconButton onClick={() => handleViewButtonClick(item)} aria-label="view details" sx={{ marginTop: '8px' }}>
                                                <VisibilityIcon fontSize="small" sx={{ color: 'blue' }} />
                                            </IconButton>
                                            <IconButton onClick={() => handleInOrOutButtonClick(item)} aria-label="Add In Or Out" sx={{ padding: '4px' }}>
                                                <AddIcon fontSize="small" sx={{ color: 'green' }} />
                                            </IconButton>
                                            <IconButton onClick={() => handleDeleteClickOpen(item)} sx={{ padding: '4px' }} aria-label="delete">
                                                <DeleteIcon fontSize="small" sx={{ color: 'red' }} />
                                            </IconButton>
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell>{item.name || 'N/A'}</TableCell>
                                            <TableCell>{item.phone || 'N/A'}</TableCell>
                                            <TableCell>{formatDateWithTimeZone(item.createTs, userTz)}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleViewButtonClick(item)} aria-label="view details">
                                                    <VisibilityIcon fontSize="small" sx={{ color: 'blue' }} />
                                                </IconButton>
                                                <span>&nbsp;&nbsp;</span>
                                                <IconButton onClick={() => handleInOrOutButtonClick(item)} aria-label="Add In Or Out">
                                                    <AddIcon fontSize="small" sx={{ color: 'green' }} />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell sx={{ padding: '8px' }}>
                                                <IconButton onClick={() => handleDeleteClickOpen(item)} sx={{ padding: '4px' }} aria-label="delete">
                                                    <DeleteIcon fontSize="small" sx={{ color: 'red' }} />
                                                </IconButton>
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} className="no-data">
                                    <Typography>No data available</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
            />

            {/* Image Preview */}
            <ImagePreview isOpen={isImagePreviewOpen} image={selectedImage} onClose={() => setImagePreviewOpen(false)} />

            {/* Employee Details */}
            <EmployeeDetails isOpen={isUserDetailsOpen} data={selectedUserData} onClose={() => setUserDetailsOpen(false)} timeZone={userTz} />
        </TableContainer>
    );
};

export default EmployeeCheckins;
