import dayjs from 'dayjs';

export const fetchReportingData = async (apiAccessToken, action, fromDateOrName, tz, phone, forShift, otherStuff) => {
    if (apiAccessToken === '') {
        return;
    }
    let apiEndpoint = '';
    if (action === 'UserDetails' || action === 'VIP' || action === 'UnReg') {
        apiEndpoint = `https://api.quikwish.com/report?action=${action}&name=${fromDateOrName}&phone=${phone}`;
    } else if (action === 'MonthlySummary') {
        apiEndpoint = `https://api.quikwish.com/report?action=${action}&forMonth=${fromDateOrName}`;
    } else if (action === 'AllRegistrations' || action === 'EmployeeCheckins' || (action === 'Registration' && fromDateOrName === '')) {
        apiEndpoint = `https://api.quikwish.com/report?action=${action}`;
    } else if (action === 'InOrOut') {
        apiEndpoint = `https://api.quikwish.com/report?action=${action}&name=${fromDateOrName}&phone=${phone}&op=${otherStuff.inOrOutAction}&dt=${otherStuff.forDateTs}`;
    } else {
        fromDateOrName = dayjs(fromDateOrName);

        let adjustedStartTime = fromDateOrName
            .hour(forShift.startTime.hour())
            .minute(forShift.startTime.minute())
            .second(forShift.startTime.second())
            .millisecond(forShift.startTime.millisecond())
            .tz(tz, true)
            .utc()
            .toISOString();

        let adjustedEndTime = fromDateOrName
            .hour(forShift.endTime.hour())
            .minute(forShift.endTime.minute())
            .second(forShift.endTime.second())
            .millisecond(forShift.endTime.millisecond())
            .add(forShift.startHour > forShift.endHour ? 1 : 0, 'day')
            .tz(tz, true)
            .utc()
            .toISOString();

        apiEndpoint = `https://api.quikwish.com/report?action=${action}&fromDate=${adjustedStartTime}&toDate=${adjustedEndTime}`;
    }
    try {
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiAccessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload();
            }
            return { data: [], message: 'Error fetching data. Please try again.', status: response.status };
        }

        const result = await response.json();
        return { data: result.data || [], message: result.message, status: response.status };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { data: [], message: 'Error fetching data. Please try again.', status: 500 };
    }
};

export const fetchConfigData = async (apiAccessToken, tz) => {
    if (apiAccessToken === '') {
        return { data: [], message: '' };
    }
    let apiEndpoint = 'https://api.quikwish.com/config';
    try {
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiAccessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload();
            }
            return { data: [], message: 'Error fetching data. Please try again.', status: response.status };
        }

        const result = await response.json();
        result.forEach((item) => {
            if (item.key) {
                const [part1, part2] = item.key.split('#'); // Split the key on '#'
                item.macAddress = part1;
                item.hostName = part2;
            }
        });
        if (result?.length > 0) {
            const formattedData = result.map((site) => ({
                ...site,
                shifts:
                    site.shifts?.map((shift) => ({
                        ...shift,
                        startTime:
                            shift.startHour !== null
                                ? dayjs()
                                      .hour(shift.startHour)
                                      .minute(0)
                                      .second(0)
                                      .millisecond(shift.startHour === 0 ? 1 : 0)
                                      .tz(tz, true)
                                : null,
                        endTime:
                            shift.endHour !== null
                                ? dayjs()
                                      .hour(shift.endHour === 0 ? 23 : shift.endHour)
                                      .minute(shift.endHour === 0 ? 59 : 0)
                                      .second(shift.endHour === 0 ? 59 : 0)
                                      .millisecond(shift.endHour === 0 ? 999 : 0)
                                      .add(shift.startHour > shift.endHour ? 1 : 0, 'day')
                                      .tz(tz, true)
                                : null,
                    })) || [],
                validUntil: site.validUntil ? dayjs(site.validUntil) : null,
                reCheckinAllowedAfterHrs: site.reCheckinAllowedAfterHrs ?? 0,
                promotions: site.promotions || [], // Ensure promotions field exists
            }));

            // console.log(formattedData[0].shifts);

            // Extract unique promotion types from the promotions array
            const allPromotions = result.flatMap((site) => site.promotions || []);
            const uniquePromotionTypes = [...new Set(allPromotions.map((promotion) => promotion.type))];
            return { data: { formattedData, allPromotions, uniquePromotionTypes } || [], message: 'Successfully Loaded Config Data', status: response.status };
        } else {
            return { data: undefined, message: 'No site data found.', status: response.status };
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return { data: [], message: 'Error fetching data. Please try again.', status: 500 };
    }
};

export const saveConfigData = async (apiAccessToken, configData) => {
    if (apiAccessToken === '') {
        return { data: [], message: '' };
    }
    delete configData.siteId;
    delete configData.validUntil;
    let apiEndpoint = 'https://api.quikwish.com/config';
    try {
        const response = await fetch(apiEndpoint, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${apiAccessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(configData),
        });

        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload();
            }
            return { data: [], message: 'Error Updating Config Data.', status: response.status };
        }

        return { data: [], message: 'Succesfully Saved Config Data', status: response.status };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { data: [], message: 'Error Updating Config Data.', status: 500 };
    }
};
