import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Input, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useStore from '../../state/index';
import { formatDateWithTimeZone } from '../../utils';

const EmployeeDetails = ({ isOpen, data, onClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects mobile screens
    const { userTz } = useStore(); // Just to preserve usage
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [totalHours, setTotalHours] = useState(null);

    useEffect(() => {
        setStartDate('');
        setEndDate('');
        setTotalHours(null);
    }, [data]);

    /**
     * Safely pick a valid date string from the record.
     * For "Check In", we often have `currentCheckInDt`.
     * For "Check Out" or unpaired actions, we might rely on `createTs`.
     * Returns `null` if neither is available.
     */
    const getDateTime = (record) => {
        if (record?.currentCheckInDt) {
            return record.currentCheckInDt;
        }
        if (record?.createTs) {
            return record.createTs;
        }
        return null;
    };

    const pairCheckInsAndOuts = (data) => {
        // 1) Sort ascending by createTs for easier pairing logic
        const ascSorted = [...data].sort((a, b) => new Date(a.createTs) - new Date(b.createTs));

        const pairedOrUnpaired = [];
        let unmatchedCheckIn = null;

        // 2) Traverse in ascending order to pair up
        for (const record of ascSorted) {
            if (record.action === 'Check In') {
                // If there's already an unmatched check-in, push it as unpaired
                if (unmatchedCheckIn) {
                    pairedOrUnpaired.push({ single: unmatchedCheckIn });
                }
                unmatchedCheckIn = record; // hold on to the new check-in
            } else if (record.action === 'Check Out') {
                // Attempt to match with an unmatched check-in
                if (unmatchedCheckIn && new Date(record.createTs) > new Date(unmatchedCheckIn.createTs)) {
                    // We have a valid CheckIn -> CheckOut
                    const checkInTime = new Date(unmatchedCheckIn.createTs);
                    const checkOutTime = new Date(record.createTs);
                    const diffInHours = ((checkOutTime - checkInTime) / (1000 * 60 * 60)).toFixed(2);

                    pairedOrUnpaired.push({
                        checkIn: unmatchedCheckIn,
                        checkOut: record,
                        diffInHours,
                    });
                    // Reset unmatchedCheckIn
                    unmatchedCheckIn = null;
                } else {
                    // Can't match this Check Out -> push as single/unpaired
                    pairedOrUnpaired.push({ single: record });
                }
            } else {
                // Any other action is just pushed as single/unpaired
                pairedOrUnpaired.push({ single: record });
            }
        }

        // 3) If something is still unmatchedCheckIn, push it as unpaired
        if (unmatchedCheckIn) {
            pairedOrUnpaired.push({ single: unmatchedCheckIn });
        }

        // 4) Sort the final array descending by whichever date is "latest"
        const getRecordDate = (item) => {
            if (item.single) {
                // For a single record, check currentCheckInDt or createTs
                const singleTime = getDateTime(item.single);
                return singleTime ? new Date(singleTime) : new Date('1970-01-01'); // fallback to earliest
            }
            // Has both checkIn and checkOut
            const inTime = getDateTime(item.checkIn);
            const outTime = getDateTime(item.checkOut);
            const inDate = inTime ? new Date(inTime) : new Date('1970-01-01');
            const outDate = outTime ? new Date(outTime) : new Date('1970-01-01');
            return outDate > inDate ? outDate : inDate;
        };

        pairedOrUnpaired.sort((a, b) => getRecordDate(b) - getRecordDate(a));

        // 5) Return the final array
        return pairedOrUnpaired;
    };

    // Generate the paired data
    const pairedData = pairCheckInsAndOuts(data);
    const employeeName = data[0]?.name || 'Employee';

    const calculateHours = () => {
        if (!startDate || !endDate || !pairedData) return;

        const start = new Date(startDate);
        const end = new Date(endDate);

        const filteredData = pairedData.filter((item) => item.checkIn && item.checkOut && new Date(item.checkIn.createTs) >= start && new Date(item.checkOut.createTs) <= end);

        const total = filteredData.reduce((sum, item) => sum + parseFloat(item.diffInHours), 0);
        setTotalHours(total.toFixed(2));
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Employee Check-In Details</DialogTitle>
            <DialogContent dividers>
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={{ margin: 0, textAlign: 'center' }}>{employeeName}</h2>
                </div>
                {pairedData.length > 0 ? (
                    <span>
                        <Card className="p-4 max-w-md mx-auto mt-4 gap-6">
                            <CardContent className="flex flex-wrap items-center gap-4">
                                {' '}
                                <label className="flex-1 min-w-[120px]">
                                    &nbsp;&nbsp;Start Date:&nbsp;&nbsp;
                                    <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="mt-2" /> {/* Added margin-top */}
                                </label>
                                <label className="flex-1 min-w-[120px]">
                                    &nbsp;&nbsp;End Date:&nbsp;&nbsp;
                                    <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="mt-2" /> {/* Added margin-top */}
                                </label>
                                <Button onClick={calculateHours} className="w-auto mt-4">
                                    {' '}
                                    Calculate
                                </Button>
                                {totalHours !== null && (
                                    <span className="text-lg font-semibold mt-4">
                                        {' '}
                                        <strong>Total Hours: {totalHours}</strong>
                                    </span>
                                )}
                            </CardContent>
                        </Card>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <strong>Photo</strong>
                                    </TableCell>
                                    <TableCell align="center">
                                        <strong>{isMobile ? 'Details' : 'Date & Time'}</strong>
                                    </TableCell>
                                    {!isMobile && (
                                        <TableCell align="center">
                                            <strong>Clocked (hours)</strong>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {pairedData.map((item, index) => {
                                    // --- 1) UNPAIRED SINGLE RECORD ---
                                    if (item.single) {
                                        const record = item.single;
                                        const recordDateTime = getDateTime(record);
                                        // If `recordDateTime` is invalid or null, skip or handle gracefully
                                        let dateStr = '';
                                        if (recordDateTime) {
                                            try {
                                                dateStr = formatDateWithTimeZone(new Date(recordDateTime), userTz);
                                            } catch (e) {
                                                // fallback to empty if there's an error
                                                dateStr = '';
                                            }
                                        }

                                        if (isMobile) {
                                            // MOBILE layout for single
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="center" sx={{ verticalAlign: 'top', padding: '8px' }}>
                                                        {record.imgBase64 ? (
                                                            <img
                                                                src={`data:image/jpeg;base64,${record.imgBase64}`}
                                                                alt={`${record.name || 'Employee'}`}
                                                                style={{
                                                                    width: '80px',
                                                                    height: '80px',
                                                                    borderRadius: '50%',
                                                                    objectFit: 'cover',
                                                                }}
                                                            />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: '80px',
                                                                    height: '80px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: '#e0e0e0',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    fontSize: '0.8rem',
                                                                    color: '#757575',
                                                                }}
                                                            >
                                                                No Photo
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ padding: '8px', fontSize: '0.9rem', lineHeight: '1.5' }}>
                                                        {/* Show relevant icon + label, if it's check in or out */}
                                                        {record.action === 'Check In' && (
                                                            <>
                                                                <strong>
                                                                    <LoginIcon
                                                                        style={{
                                                                            color: 'green',
                                                                            verticalAlign: 'middle',
                                                                            fontSize: '1.2rem',
                                                                        }}
                                                                    />{' '}
                                                                    Check In:
                                                                </strong>{' '}
                                                                {dateStr}
                                                            </>
                                                        )}
                                                        {record.action === 'Check Out' && (
                                                            <>
                                                                <strong>
                                                                    <LogoutIcon
                                                                        style={{
                                                                            color: 'red',
                                                                            verticalAlign: 'middle',
                                                                            fontSize: '1.2rem',
                                                                        }}
                                                                    />{' '}
                                                                    Check Out:
                                                                </strong>{' '}
                                                                {dateStr}
                                                            </>
                                                        )}
                                                        {/* For other actions, just show the action */}
                                                        {record.action !== 'Check In' && record.action !== 'Check Out' && (
                                                            <>
                                                                <strong>Action:</strong> {record.action} — {dateStr}
                                                            </>
                                                        )}
                                                        <br />
                                                        {/* Unpaired -> we have no hours to display */}
                                                        <strong>Hours Clocked:</strong> 0 hours
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }

                                        // DESKTOP layout for single
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="center">
                                                    {record.imgBase64 ? (
                                                        <img
                                                            src={`data:image/jpeg;base64,${record.imgBase64}`}
                                                            alt={`${record.name || 'Employee'}`}
                                                            style={{
                                                                width: '100px',
                                                                height: '100px',
                                                                borderRadius: '50%',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: '100px',
                                                                height: '100px',
                                                                borderRadius: '50%',
                                                                backgroundColor: '#e0e0e0',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                fontSize: '0.8rem',
                                                                color: '#757575',
                                                            }}
                                                        >
                                                            No Photo
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {/* Show icon + date/time similarly */}
                                                    {record.action === 'Check In' && (
                                                        <>
                                                            <LoginIcon
                                                                style={{
                                                                    color: 'green',
                                                                    fontSize: '1.5rem',
                                                                    verticalAlign: 'middle',
                                                                }}
                                                            />{' '}
                                                            <span>{dateStr && dateStr.split(',')[0]}</span>
                                                            <span
                                                                style={{
                                                                    fontSize: '1.5rem',
                                                                    fontWeight: 'bold',
                                                                    display: 'block',
                                                                }}
                                                            >
                                                                {dateStr && dateStr.split(',').slice(1).join(',')}
                                                            </span>
                                                        </>
                                                    )}
                                                    {record.action === 'Check Out' && (
                                                        <>
                                                            <LogoutIcon
                                                                style={{
                                                                    color: 'red',
                                                                    fontSize: '1.5rem',
                                                                    verticalAlign: 'middle',
                                                                }}
                                                            />{' '}
                                                            <span>{dateStr && dateStr.split(',')[0]}</span>
                                                            <span
                                                                style={{
                                                                    fontSize: '1.5rem',
                                                                    fontWeight: 'bold',
                                                                    display: 'block',
                                                                }}
                                                            >
                                                                {dateStr && dateStr.split(',').slice(1).join(',')}
                                                            </span>
                                                        </>
                                                    )}
                                                    {record.action !== 'Check In' && record.action !== 'Check Out' && (
                                                        <>
                                                            <span style={{ fontWeight: 'bold' }}>{record.action}</span>
                                                            <br />
                                                            <span>{dateStr}</span>
                                                        </>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                    {/* Unpaired => 0 hours */}0 hours
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }

                                    // --- 2) PAIRED RECORD (checkIn + checkOut + diffInHours) ---
                                    const { checkIn, checkOut, diffInHours } = item;

                                    // For each, get a valid date
                                    const checkInDateTime = getDateTime(checkIn);
                                    const checkOutDateTime = checkOut && getDateTime(checkOut);
                                    const formattedCheckIn = checkInDateTime ? formatDateWithTimeZone(new Date(checkInDateTime), userTz) : '';
                                    const formattedCheckOut = checkOutDateTime ? formatDateWithTimeZone(new Date(checkOutDateTime), userTz) : '';

                                    if (isMobile) {
                                        // MOBILE layout for pair
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="center" sx={{ verticalAlign: 'top', padding: '8px' }}>
                                                    {checkIn.imgBase64 ? (
                                                        <img
                                                            src={`data:image/jpeg;base64,${checkIn.imgBase64}`}
                                                            alt={`${checkIn.name || 'Employee'}`}
                                                            style={{
                                                                width: '80px',
                                                                height: '80px',
                                                                borderRadius: '50%',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: '80px',
                                                                height: '80px',
                                                                borderRadius: '50%',
                                                                backgroundColor: '#e0e0e0',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                fontSize: '0.8rem',
                                                                color: '#757575',
                                                            }}
                                                        >
                                                            No Photo
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell align="left" sx={{ padding: '8px', fontSize: '0.9rem', lineHeight: '1.5' }}>
                                                    <strong>
                                                        <LoginIcon style={{ color: 'green', verticalAlign: 'middle', fontSize: '1.2rem' }} /> Check In:
                                                    </strong>{' '}
                                                    {formattedCheckIn}
                                                    <br />
                                                    {checkOut && (
                                                        <>
                                                            <strong>
                                                                <LogoutIcon
                                                                    style={{
                                                                        color: 'red',
                                                                        verticalAlign: 'middle',
                                                                        fontSize: '1.2rem',
                                                                    }}
                                                                />{' '}
                                                                Check Out:
                                                            </strong>{' '}
                                                            {formattedCheckOut}
                                                            <br />
                                                        </>
                                                    )}
                                                    <strong>Hours Clocked:</strong> <strong style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{diffInHours} hours</strong>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }

                                    // DESKTOP layout for pair
                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell rowSpan={2} align="center">
                                                    {checkIn.imgBase64 ? (
                                                        <img
                                                            src={`data:image/jpeg;base64,${checkIn.imgBase64}`}
                                                            alt={`${checkIn.name || 'Employee'}`}
                                                            style={{
                                                                width: '100px',
                                                                height: '100px',
                                                                borderRadius: '50%',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: '100px',
                                                                height: '100px',
                                                                borderRadius: '50%',
                                                                backgroundColor: '#e0e0e0',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                fontSize: '0.8rem',
                                                                color: '#757575',
                                                            }}
                                                        >
                                                            No Photo
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {checkOut && (
                                                        <>
                                                            <LogoutIcon style={{ color: 'red', fontSize: '1.5rem', verticalAlign: 'middle' }} /> <span>{formattedCheckOut.split(',')[0]}</span>
                                                            <span
                                                                style={{
                                                                    fontSize: '1.5rem',
                                                                    fontWeight: 'bold',
                                                                    display: 'block',
                                                                }}
                                                            >
                                                                {formattedCheckOut.split(',').slice(1).join(',')}
                                                            </span>
                                                        </>
                                                    )}
                                                </TableCell>
                                                <TableCell rowSpan={2} align="center" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                    {diffInHours} hours
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">
                                                    <LoginIcon style={{ color: 'green', fontSize: '1.5rem', verticalAlign: 'middle' }} /> <span>{formattedCheckIn.split(',')[0]}</span>
                                                    <span
                                                        style={{
                                                            fontSize: '1.5rem',
                                                            fontWeight: 'bold',
                                                            display: 'block',
                                                        }}
                                                    >
                                                        {formattedCheckIn.split(',').slice(1).join(',')}
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </span>
                ) : (
                    <p>No details available</p>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmployeeDetails;
